import React from "react"
import { css } from "@emotion/core"
import * as Icon from "react-feather"

import Layout from "../components/layout/"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"
import MiniHeading from "../components/miniHeading"
import Text from "../components/Text"

import performancesData from "../cms/performances"

export default () => {
  const performances = performancesData.calendar
  let upcomingPerformances = []
  let previousPerformances = []

  performances.forEach(performance => {
    const now = new Date().getTime()
    const performanceDate = new Date(new Date(performance.date).setHours(23, 59, 59))
    const isOver = now > performanceDate

    if(isOver) {
      previousPerformances.push(performance)
    } else {
      upcomingPerformances.push(performance)
    }
  })

  return (
    <Layout>
      <Head title="Performance Calendar" />

      <PageHeading text="Performance Calendar" centered />

      <MiniHeading text="Upcoming" />

      {upcomingPerformances.map(performance => (
        <Block
          key={performance.date}
          performance={performance}
        />
      ))}

      {upcomingPerformances.length === 0 && (
        <p
          css={css`
            color: var(--text-light-gray);
          `}
        >
          {performancesData.no_upcoming_performances}
        </p>
      )}

      <MiniHeading text="Past Performances" />

      {previousPerformances.reverse().map(performance => (
        <Block
          key={performance.date}
          performance={performance}
          grayscale="true"
        />
      ))}
    </Layout>
  )
}

const Block = ({ performance, grayscale }) => (
  <div
    css={css`
      padding: 1rem;
      border: .2rem solid hsla(9, 30%, 20%, .2);
      border-radius: .5rem;
      margin-bottom: 1rem;
      ${grayscale ? "filter: grayscale(100%); opacity: .6;" : ""}
    `}
  >
    {performance.city_state ? 
      <p
        css={css`
          background-color: var(--background-dark-secondary);
          color: white;
          max-width: max-content;
          font-weight: 600;
          padding: .3rem .6rem;
          margin-bottom: .5rem;
          font-size: 1rem;
          border-radius: .3rem;
        `}
      >
        {performance.city_state}
      </p>
    : ""}

    <p
      css={css`
        font-weight: 500;
        font-size: 1.3rem;
        margin-bottom: 1rem;
      `}
    >
      {new Date(performance.date).toLocaleString("en-US", {
        timeZone: "America/New_York",
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        weekday: "long"
      }).replace(" AM", "am").replace(" PM", "pm")}
    </p>

    {performance.updates && (
      <p
        css={css`
          margin-bottom: 1rem;
          color: var(--text-error);
          font-weight: 600;
          line-height: 1.4;
        `}
      >
        {performance.updates}
      </p>
    )}

    <div
      css={css`
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
        grid-row-gap: .7rem;
        grid-column-gap: 1rem;
      `}
    >
      <InfoLine
        text={performance.location}
        icon={<Icon.Home />}
        showIf={performance.location}
      />

      <InfoLine
        text={performance.address}
        icon={<Icon.MapPin />}
        showIf={performance.address}
      />

      <InfoLine
        text={"Sponsor: " + performance.sponsor}
        icon={<Icon.Heart />}
        showIf={performance.sponsor}
      />

      <InfoLine
        text={`${performance.percentage_of_proceeds}% of proceeds benefit: ${performance.proceeds_benefit}`}
        icon={<Icon.Percent />}
        showIf={performance.percentage_of_proceeds}
      />

      <InfoLine
        text={performance.tickets}
        icon={<Icon.Tag />}
        showIf={performance.tickets}
        bold={true}
      />
    </div>
  </div>
)

const InfoLine = props => (
  <React.Fragment>
    {props.showIf ? <React.Fragment>
      <div
        css={css`
          display: inline-block;

          svg {
            height: 1.3rem;
            width: auto;
          }

          > * {
            stroke: var(--text-light-gray);
            stroke-width: 2px;
          }
        `}
      >
        {props.icon}
      </div>

      <Text
        style={css`
          line-height: 1.6;
          word-wrap: break-word;
          margin-bottom: 0;
          font-size: 1rem;
        `}
        bold={props.bold}
      >
        {props.text}
      </Text>
    </React.Fragment>: ""}
  </React.Fragment>
)
